<template>
  <div id="request-modal-preview" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <AlertCircleIcon class="w-16 h-16 text-theme-main mx-auto mt-3" />
            <div class="text-gray-600 mt-2 dark:text-gray-400">
              <I18n code="menuPage.request.desc1"></I18n>
            </div>
            <div class="mt-2">
              <label for="menusNeeded">
                <I18n code="menuPage.request.desc2"></I18n>
              </label>
              <input name="menusNeeded" type="number" :min="1" class="
                  form-control
                  w-full
                  dark:bg-dark-9 dark:placeholder-white
                  mt-2
                " required v-model="menusNeeded" :placeholder="i18n('menuPage.request.menuNum')" />
            </div>
          </div>
          <div class="flex justify-center items-center px-5 pb-8">
            <!-- <button type="button" data-dismiss="modal" class="btn bg-theme-main w-24" @click="doSubmit()">
              {{ i18n(`common.request`) }}
            </button> -->
            <AppButton type="button" class="btn bg-theme-action-1 text-white cursor-pointer"
              :class="!saveLoading ? 'w-24' : ''" :disabled="saveLoading || menusNeeded <= 0" :loading="saveLoading"
              @click="doSubmit()">
              <strong>{{ i18n('common.request') }}</strong>
              <template v-slot:loading>
                <span>{{ i18n('common.loading') }}</span>
                <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px" />
              </template>
            </AppButton>
            <button type="button" data-dismiss="modal" @click="doReset()" class="
                btn btn-outline-secondary
                w-24
                dark:border-dark-5 dark:text-gray-300
                ml-1
                mr-1
              ">
              {{ i18n(`common.cancel`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  setup() {
    const hideModal = () => {
      cash('#request-modal-preview').modal('hide')
    }

    return {
      hideModal
    }
  },
  data() {
    return {
      menusNeeded: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      saveLoading: 'request/form/saveLoading'
    })
  },
  methods: {
    ...mapActions({
      doCreate: 'request/form/doCreate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    doReset() {
      this.menusNeeded = null
    },
    async doSubmit() {
      const values = {
        menusNeeded: this.menusNeeded
      }
      await this.doCreate(values)
      this.doReset()
      this.hideModal()
    }
  }
}
</script>

<style>

</style>
